import { useQuery, useMutation, gql } from '@apollo/client'

const fakePost = gql`
  mutation($test: test) {
    test(test: $test)
  }
`
const fakeRemove = gql`
  mutation($test: test) {
    test(test: $test)
  }
`

/**
 * useDataRequest
 * @hook
 *
 */
function useDataRequest(props) {
  const { get, post = fakePost, remove = fakeRemove, options = {} } = props
  const { loading, error, data, refetch } = useQuery(get, {
    ...options,
  })
  const [postData] = useMutation(post)
  const [removeData] = useMutation(remove)

  return {
    loading,
    error,
    data,
    postData,
    refetch,
    removeData,
  }
}

export default useDataRequest
