import React from 'react'
import PropTypes from 'prop-types'

import { useModal } from '~contexts/ModalContext'
import { stripTags } from '~utils/functions'
import constants from '~utils/constants'

import Button from '~components/Button'
import Input from '~components/Input'
import Label from '~components/Label'
import Fieldset from '~components/Fieldset'
import Table from '~components/Table'
import TableRow from '~components/TableRow'
import TableRowItem from '~components/TableRowItem'
import Title from '~components/Title'
import Wrapper from '~components/Wrapper'
import Wysiwyg from '~components/Wysiwyg'

const { STATUS } = constants

/**
 * VideoModal
 * @component
 *
 */
const VideoModal = ({ item, onDelete, onSave, closeModal }) => {
  const [currentItem, setCurrentItem] = React.useState(item || {})
  const update = (value, field) =>
    setCurrentItem({ ...currentItem, [field]: value })
  return (
    <Wrapper>
      <div className="header">
        <Title type="h2">Multiple videos</Title>
      </div>
      <div className="body">
        <Wrapper>
          <Label>
            Link :&nbsp;
            <Input
              width="30em"
              value={currentItem.link}
              onChange={(v) => update(v, 'link')}
            />
          </Label>
        </Wrapper>
        <Wrapper>
          <Label>
            Content :&nbsp;
            <Wysiwyg
              value={currentItem.content}
              onChange={(v) => update(v, 'content')}
            />
          </Label>
        </Wrapper>
      </div>
      <div className="footer">
        <Button color={STATUS.INFO} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color={STATUS.WARNING}
          disabled={!currentItem.link}
          onClick={() => onSave(currentItem)}>
          Validate
        </Button>
        <Button color={STATUS.DANGER} onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Wrapper>
  )
}
VideoModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

/**
 *
 * DataFormJsonvideos
 * @component
 *
 */
function DataFormJsonvideos(props) {
  const { values, handleChange } = props
  const { videosGroup } = values
  const [datas, setDatas] = React.useState(videosGroup)
  const { activeModal, closeModal } = useModal()

  const onChange = (nextDatas) => {
    const updatedJson = { values: { videosGroup: nextDatas } }
    handleChange(JSON.stringify(updatedJson))
  }

  const removeItem = (index) => {
    if (index === undefined) {
      closeModal()
      return
    }
    const nextDatas = datas
    nextDatas.videos.splice(index, 1)
    setDatas(nextDatas)
    onChange(nextDatas)
    closeModal()
  }
  const saveItem = (item, index) => {
    const nextDatas = datas
    if (index) nextDatas.videos.splice(index, 1, item)
    else nextDatas.videos.unshift(item)
    setDatas(nextDatas)
    onChange(nextDatas)
    closeModal()
  }

  const handleClick = (item, index) => () => {
    activeModal(
      <VideoModal
        item={item}
        onSave={(v) => saveItem(v, index)}
        onDelete={() => removeItem(index)}
        closeModal={closeModal}
      />,
    )
  }
  const columns = '3em 1fr 1fr 9em'
  return (
    <div>
      <Fieldset>
        <Label>
          Title :&nbsp;
          <Input value={datas && datas.title} />
        </Label>
      </Fieldset>
      <Fieldset>
        <Table width="100%" height="400px">
          <TableRow head columns={columns}>
            <TableRowItem head> Index ({datas.videos.length}) </TableRowItem>
            <TableRowItem head> Content </TableRowItem>
            <TableRowItem head> Link </TableRowItem>
            <TableRowItem head> Update/Delete </TableRowItem>
          </TableRow>
          {datas.videos.map((video, index) => (
            <TableRow key={video.link} columns={columns}>
              <TableRowItem center> {index} </TableRowItem>
              <TableRowItem> {stripTags(video.content)} </TableRowItem>
              <TableRowItem>{video.link} </TableRowItem>
              <TableRowItem center>
                <Button onClick={handleClick(video, index)}>
                  update / delete
                </Button>
              </TableRowItem>
            </TableRow>
          ))}
        </Table>
        <Button onClick={handleClick()}>Add item(+)</Button>
      </Fieldset>
    </div>
  )
}
DataFormJsonvideos.defaultProps = {
  values: {},
  handleChange: () => null,
}
DataFormJsonvideos.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func,
}

export default DataFormJsonvideos
