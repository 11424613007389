import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { useUser } from '~contexts/UserContext'
import useAlert from '~hooks/useAlert'
import { checkEmail } from '~utils/functions'

import AuthForm from '~Auth/components/AuthForm'
import AuthFormGroup from '~Auth/components/AuthFormGroup'
import AuthInput from '~Auth/components/AuthInput'
import AuthLoading from '~Auth/components/AuthLoading'
import AuthTitle from '~Auth/components/AuthTitle'
import WrapperAuth from '~Auth/components/WrapperAuth'

import DefaultAuth from '~Auth/constructors/DefaultAuth'

/**
 * AuthenticationContainer
 * @component
 *
 */
function AuthenticationContainer(props) {
  const { children } = props
  const { isAuthenticated, updateUser, logout, hasAccessToken } = useUser()
  const { AlertBlock, setAlert, alerts } = useAlert()
  const [auth, setAuth] = React.useState(new DefaultAuth())
  const [isLoading, setLoadingState] = React.useState(false)
  const [isLogin, setLoginState] = React.useState(true)

  const { post } = DefaultAuth.getQueries()
  const [postAuth] = useMutation(post.request)

  const setValue = (field, value) => {
    setAuth(new DefaultAuth({ ...auth, [field]: value }))
  }
  const clearAuthPayload = () => setAuth(new DefaultAuth())

  const submitAuthRequest = () => {
    setLoadingState(true)
    return postAuth({ variables: auth.parse() })
      .then(({ data }) => {
        if (data && data.postAuth) {
          const { accessToken, ...nextUser } = data.postAuth
          clearAuthPayload()
          updateUser({ user: nextUser, accessToken })
        } else {
          setAlert(alerts.AUTH_SERVER_ERRORS, 'Session expired')
          logout()
        }
      })
      .catch((e) => setAlert(alerts.AUTH_SERVER_ERRORS, e.message))
      .finally(() => setLoadingState(false))
  }

  const handleSubmit = () => {
    if (!auth.checkEmail()) return setAlert(alerts.AUTH_INCORECT_EMAIL)
    if (!auth.checkPassword()) return setAlert(alerts.AUTH_INCORECT_PASSWORD)
    return submitAuthRequest()
  }

  React.useEffect(() => {
    if (hasAccessToken) submitAuthRequest()
  }, [])

  if (isAuthenticated) return <div>{children}</div>
  if (isLoading) {
    return (
      <WrapperAuth>
        <AuthLoading isLogin={isLogin} />
      </WrapperAuth>
    )
  }
  return (
    <WrapperAuth>
      <AuthTitle>{isLogin ? 'Connexion' : 'Registration'}</AuthTitle>
      <AuthForm
        action={handleSubmit}
        isValid={auth.isValid()}
        swicthMode={() => setLoginState(!isLogin)}>
        <AlertBlock />
        <AuthFormGroup legend="login">
          <AuthInput
            value={auth.email}
            handleChange={(v) => setValue('email', v)}
            checkValid={(v) => checkEmail(v)}
          />
        </AuthFormGroup>
        <AuthFormGroup legend="password">
          <AuthInput
            value={auth.password}
            password
            handleChange={(v) => setValue('password', v)}
          />
        </AuthFormGroup>
      </AuthForm>
    </WrapperAuth>
  )
}
AuthenticationContainer.defaultProps = {}
AuthenticationContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticationContainer
