import React from 'react'
import PropTypes from 'prop-types'

const ModalContext = React.createContext()

/**
 * ModalProvider
 * @component
 * @context
 *
 */
function ModalProvider(props) {
  const { children } = props
  const [isModalActive, setModalActive] = React.useState(false)
  const [Component, setComponent] = React.useState(<div />)
  const closeModal = () => {
    setModalActive(false)
    setComponent(<div />)
  }
  const value = {
    isModalActive,
    closeModal,
    Component,
    activeModal(component) {
      setComponent(component)
      setModalActive(true)
    },
  }

  return (
    <ModalContext.Provider {...props} value={value}>
      {children}
    </ModalContext.Provider>
  )
}
ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * useModal
 * @return context
 *
 */
export const useModal = () => {
  const context = React.useContext(ModalContext)
  if (!context) throw new Error('ModalContext must be called in ModalProvider')
  return context
}

export default ModalProvider
