import React from 'react'
// import PropTypes from 'prop-types'

import Title from '~components/Title'

/**
 * HomeTitle
 * @component
 *
 */
function HomeTitle(props) {
  return <Title {...props} />
}
HomeTitle.defaultProps = {}
HomeTitle.propTypes = {}

export default HomeTitle
