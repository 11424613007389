import React from 'react'
import PropTypes from 'prop-types'

import Wrapper from '~components/Wrapper'

/**
 * DataRequestManager
 * @component
 *
 */
function DataRequestManager(props) {
  const { isRequestLoading, errorRequest } = props
  if (isRequestLoading) {
    return <Wrapper>loading ... </Wrapper>
  }
  if (errorRequest) {
    return <Wrapper> errorRequest </Wrapper>
  }
  return <Wrapper {...props} />
}
DataRequestManager.defaultProps = {
  errorRequest: null,
}
DataRequestManager.propTypes = {
  isRequestLoading: PropTypes.bool.isRequired,
  errorRequest: PropTypes.objectOf(PropTypes.any),
}

export default DataRequestManager
