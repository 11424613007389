import React from 'react'
import WrapperBody from '~Layout/components/WrapperBody'

/**
 * BodyContainer
 * @component
 *
 */
function BodyContainer(props) {
  return <WrapperBody {...props} />
}

export default BodyContainer
