import React from 'react'
// import PropTypes from 'prop-types'

import { useModal } from 'contexts/ModalContext'
import LayoutModal from '~Layout/components/LayoutModal'

/**
 * ModalContainer
 * @component
 *
 */
function ModalContainer(props) {
  const { isModalActive, closeModal, Component } = useModal()
  return (
    <LayoutModal {...props} open={isModalActive} closeModal={closeModal}>
      {Component}
    </LayoutModal>
  )
}
ModalContainer.defaultProps = {}
ModalContainer.propTypes = {}

export default ModalContainer
