import React from 'react'
import PropTypes from 'prop-types'
import ThemeProvider from '~contexts/ThemeContext'
import UserProvider from '~contexts/UserContext'
import ErrorProvider from '~contexts/ErrorContext'
import ModalProvider from '~contexts/ModalContext'

function Contexts({ children }) {
  return (
    <ErrorProvider>
      <UserProvider>
        <ThemeProvider>
          <ModalProvider>{children}</ModalProvider>
        </ThemeProvider>
      </UserProvider>
    </ErrorProvider>
  )
}

Contexts.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Contexts
