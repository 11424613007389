import { gql } from '@apollo/client'

import constants from '~utils/constants'

import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const {
  BOOLEAN,
  IMAGE,
  SELECT,
  STRING,
  TEXTAREA_WYSIWYG,
} = constants.FIELD_TYPES

const FIELDS = `
  id
  category { name id }
  features { name id }
  file { id url }
  intro
  isExclusive
  name
  sections { name id }
  slug
  isHidden
`

/**
 *
 * DefaultType
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultType extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    const category = obj.category || {}
    this.category = {
      key: category.id || category.key || '',
      title: category.name || category.title || '',
    }
    this.file = obj.file || null
    this.intro = obj.intro || ''
    this.isExclusive = obj.isExclusive || false
    this.isHidden = obj.isHidden || false
    this.name = obj.name || ''
  }

  static fields(options = {}) {
    const { skip = [], selectList = [] } = options
    const categoryList = selectList[0] || [{}]
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
      {
        fieldName: 'category',
        fieldType: SELECT,
        options: categoryList.map(({ name, id }) => ({
          title: name,
          key: id,
        })),
      },
      { fieldName: 'intro', fieldType: TEXTAREA_WYSIWYG },
      { fieldName: 'isExclusive', fieldType: BOOLEAN },
      { fieldName: 'isHidden', fieldType: BOOLEAN },
      { fieldName: 'file', fieldType: IMAGE },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getType',
        selectNamesList: ['allCategories'],
        request: gql` query Type ($id: ID!){
          getType (id: $id){
            ${FIELDS}
          }
          allCategories {
            id
            name
          }
        }
      `,
      },
      all: {
        name: 'allTypes',
        request: gql` query allTypes {
          allTypes {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postType($input: TypeInput!) {
            postType(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.name !== '', this.file].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        category: this.category.key,
        file: this.file instanceof File ? this.file : null,
        id: this.id,
        intro: this.intro,
        isExclusive: this.isExclusive,
        name: this.name,
        isHidden: this.isHidden,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultType
