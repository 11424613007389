import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '~components/TableRow'

import constants from '~utils/constants'

const { DATAS } = constants
const COLUMNS = {
  [DATAS.HOMECAROUSEL]: '5em 1fr 1fr 14em',
  [DATAS.CATEGORY]: '5em 1fr 1fr 11em 14em',
  [DATAS.HOMEVIDEOS]: '5em 1fr 1fr 5em 14em',
  [DATAS.FEATURE]: '5em 1fr 1fr 14em',
  [DATAS.TYPE]: '1fr 1fr 6em 5em 12em 14em',
  [DATAS.SECTION]: '4em 2fr 2fr 2fr 3fr 14em',
  [DATAS.USER]: '1fr 1fr 1fr 5em 6em 14em',
}

/**
 * DataTableRow
 * @component
 *
 */
function DataTableRow(props) {
  const { dataType, head, children, ...rest } = props

  const columns = COLUMNS[dataType]

  return (
    <TableRow columns={columns} head={head} {...rest}>
      {children}
    </TableRow>
  )
}

DataTableRow.defaultProps = {
  children: undefined,
  head: false,
}
DataTableRow.propTypes = {
  children: PropTypes.node,
  dataType: PropTypes.oneOf(Object.values(DATAS)).isRequired,
  head: PropTypes.bool,
}

export default DataTableRow
