import { gql } from '@apollo/client'

import constants from '~utils/constants'

import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const {
  IMAGE,
  SELECT,
  NUMBER,
  STRING,
  TEXTAREA_WYSIWYG,
} = constants.FIELD_TYPES

const FIELDS = `
  id
  content
  display
  file { id url }
  name
  order
  subtitle
  type { id name }
`

/**
 *
 * DefaultSection
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultSection extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    const { type = {} } = obj
    this.content = obj.content || ''
    this.display = obj.display || ''
    this.file = obj.file || null
    this.name = obj.name || ''
    this.order = obj.order || -1
    this.subtitle = obj.subtitle || ''
    this.type = {
      key: type.id || type.key || '',
      title: type.name || type.title || '',
    }
  }

  static fields(options = {}) {
    const { skip = [], selectList = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'order', fieldType: NUMBER },
      { fieldName: 'name', fieldType: STRING },
      {
        fieldName: 'type',
        fieldType: SELECT,
        options:
          selectList[0] &&
          selectList[0].map(({ name, id }) => ({
            title: name,
            key: id,
          })),
      },
      { fieldName: 'subtitle', fieldType: STRING },
      {
        fieldName: 'display',
        fieldType: SELECT,
        options: ['block', 'inline-left', 'inline-right'].map((disp) => ({
          key: disp,
          title: disp,
        })),
      },
      { fieldName: 'content', fieldType: TEXTAREA_WYSIWYG },
      { fieldName: 'file', fieldType: IMAGE },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getSection',
        selectNamesList: ['allTypes'],
        request: gql` query Type ($id: ID!){
          getSection (id: $id){
            ${FIELDS}
          }
          allTypes {
            id
            name
          }
        }
      `,
      },
      all: {
        name: 'allSections',
        request: gql` query allSections {
          allSections {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postSection($input: SectionInput!) {
            postSection(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [
      this.content !== '',
      this.display !== '',
      this.file,
      this.name !== '',
      this.order > -1,
    ].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        content: this.content,
        display: this.display,
        file: this.file instanceof File ? this.file : null,
        id: this.id,
        name: this.name,
        order: this.order,
        subtitle: this.subtitle,
        type: this.type.key,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultSection
