import React from 'react'
import PropTypes from 'prop-types'
import { colorsThemesList } from '~contexts/ThemeContext'
import { withAsyncErrorHandling } from '~contexts/ErrorContext'
import BaseButton from './style'

/**
 * Button
 * @component
 *
 */
function Button(props) {
  const { children, color, unboxed, inversed, rounded, ...rest } = props

  const styledProps = { ...rest }
  if (rounded) styledProps.className = `${styledProps.className} rounded`
  if (unboxed) styledProps.className = `${styledProps.className} unboxed`

  const inversedColors = inversed ? 1 : 0

  return (
    <BaseButton color={color} inversed={inversedColors} {...styledProps}>
      {children}
    </BaseButton>
  )
}
Button.defaultProps = {
  children: undefined,
  className: '',
  color: colorsThemesList[0],
  disabled: false,
  height: '',
  inversed: false,
  onBlur: () => null,
  onClick: () => null,
  rounded: false,
  type: 'button',
  unboxed: false,
  width: '',
}
Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(colorsThemesList),
  disabled: PropTypes.bool,
  height: PropTypes.string,
  inversed: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  type: PropTypes.string,
  unboxed: PropTypes.bool,
  width: PropTypes.string,
}

export default withAsyncErrorHandling(Button)
