import React from 'react'
// import PropTypes from 'prop-types'

import Input from '~components/Input'

/**
 * AuthInput
 * @component
 *
 */
function AuthInput(props) {
  return <Input {...props} />
}
AuthInput.defaultProps = {}
AuthInput.propTypes = {}

export default AuthInput
