import React from 'react'
import PropTypes from 'prop-types'
import Table from '~components/Table'

/**
 * DataTable
 * @component
 *
 */
function DataTable(props) {
  const { children } = props
  return <Table>{children}</Table>
}
DataTable.defaultProps = {}
DataTable.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DataTable
