/**
 * strUcFirst
 * @function
 * @desc :: first letter capitalized
 * @eturn {Sring}
 *
 */
function strUcFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

module.exports = strUcFirst
