import React from 'react'
// import PropTypes from 'prop-types'

import List from '~components/List'

/**
 * SidebarList
 * @component
 *
 */
function SidebarList(props) {
  return <List boxed {...props} />
}
SidebarList.defaultProps = {}
SidebarList.propTypes = {}

export default SidebarList
