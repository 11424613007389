import React from 'react'
import WrapperFooter from '~Layout/components/WrapperFooter'

/**
 * FooterContainer
 * @component
 *
 */
function FooterContainer() {
  return <WrapperFooter>Dashboard | CRC Paris</WrapperFooter>
}

export default FooterContainer
