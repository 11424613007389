import React from 'react'
import ReactDOM from 'react-dom'

import GraphQLProvider from '~utils/api/graphQL'
import Contexts from '~contexts'

import App from './App'
import 'normalize.css'
import './index.css'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <GraphQLProvider>
      <Contexts>
        <App />
      </Contexts>
    </GraphQLProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
