import React from 'react'
import PropTypes from 'prop-types'

import WrapperContent from '~Layout/components/WrapperContent'

/**
 * ContentContainer
 * @component
 *
 */
function ContentContainer(props) {
  const { children } = props
  return <WrapperContent>{children}</WrapperContent>
}

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContentContainer
