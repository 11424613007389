export default {
  yellow: '#feef01',
  yellow_orange: '#ffbc2b',
  orange: '#ff9900',
  red: '#ff0000',
  pink: '#fb0599',
  violet: '#a204a2',
  dark_blue: '#04047f',
  blue: '#1d4c64',
  green: '#00d700',
  dark: '#000',
  white: '#fcf9f9',
  grey: '#6d6f70',
}
