import React from 'react'
import { useError } from '~contexts/ErrorContext'
import WrapperError from '~ErrorHandler/components/WrapperError'
import ErrorLink from '~ErrorHandler/components/ErrorLink'
import ErrorTitle from '~ErrorHandler/components/ErrorTitle'

const backMessage = 'Back to Home'

/**
 * ServerContainer
 * @component
 *
 */
function ServerContainer() {
  const { errorMessage, resetError } = useError()
  return (
    <WrapperError fullpage>
      <ErrorTitle>Server Error (500) </ErrorTitle>
      <p>{errorMessage}</p>
      <ErrorLink to="/" onClick={resetError}>
        {backMessage}
      </ErrorLink>
    </WrapperError>
  )
}
ServerContainer.defaultProps = {}
ServerContainer.propTypes = {}

export default ServerContainer
