import React from 'react'
import PropTypes from 'prop-types'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import Button from '~components/Button'
import Label from '~components/Label'
import Title from '~components/Title'
import Wrapper from '~components/Wrapper'
import Wysiwyg from '~components/Wysiwyg'
import { useModal } from '~contexts/ModalContext'

import constants from '~utils/constants'

const { STATUS } = constants

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar)

const EVENT_FORMAT = {
  start: '',
  end: '',
  title: 'Modifier/Supprimer',
  allDay: true,
  imageUrl: '',
  content: '',
}

const EventModal = (props) => {
  const { event, closeModal, onDelete, onSave } = props
  const [content, setContent] = React.useState(event.content || '')
  return (
    <Wrapper>
      <div className="header">
        <Title type="h2">Evènement Calendrier</Title>
      </div>
      <div className="body">
        <Wrapper>
          <Label>
            Content :&nbsp;
            <Wysiwyg value={content} onChange={(v) => setContent(v)} />
          </Label>
        </Wrapper>
      </div>
      <div className="footer">
        <Button color={STATUS.INFO} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color={STATUS.WARNING}
          onClick={() => onSave({ ...event, content })}>
          Validate
        </Button>
        <Button color={STATUS.DANGER} onClick={onDelete}>
          Delete
        </Button>
      </div>
    </Wrapper>
  )
}
EventModal.defaultProps = {
  event: {},
}
EventModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  event: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

/**
 *
 * DataFormJsonCalendar
 * @component
 *
 */
function DataFormJsonCalendar(props) {
  const { values, handleChange } = props
  const [events, setEvents] = React.useState(values)
  const { activeModal, closeModal } = useModal()

  const updateEvents = async (idx, event, remove = false) => {
    const nextEvents = [...events]
    if (idx === -1) nextEvents.push(event)
    else if (remove) nextEvents.splice(idx, 1)
    else nextEvents.splice(idx, 1, event)
    setEvents(nextEvents)
    handleChange({ values: nextEvents })
  }

  const moveEvent = ({ event, start, end }) => {
    const idx = events.findIndex((ev) => ev.start === event.start)
    const updatedEvent = { ...event, start, end }
    updateEvents(idx, updatedEvent)
  }

  const handleModalRecord = (event) => {
    const idx = events.findIndex((evt) => {
      const res =
        new Date(evt.start).toDateString() ===
        new Date(event.start).toDateString()
      return res
    })
    const updatedEvent = { ...EVENT_FORMAT, ...event }
    updateEvents(idx, updatedEvent)
    closeModal()
  }

  const deleteModalRecord = (event) => {
    const idx = events.findIndex((evt) => evt.start === event.start)
    updateEvents(idx, event, true)
    closeModal()
  }

  const selectEvent = (e) => {
    activeModal(
      <EventModal
        event={e}
        closeModal={closeModal}
        onSave={handleModalRecord}
        onDelete={() => deleteModalRecord(e)}
      />,
    )
  }

  const onSelectSlot = (e) => {
    const nextEvent = { ...EVENT_FORMAT, ...e }
    const oldEvent = events.find(({ start }) => {
      return new Date(start).toDateString() === new Date(e.start).toDateString()
    })
    if (oldEvent) {
      selectEvent(oldEvent)
    } else {
      selectEvent(nextEvent)
    }
  }
  return (
    <Wrapper>
      <DnDCalendar
        defaultDate={moment().toDate()}
        defaultView="month"
        events={events}
        localizer={localizer}
        max={new Date(moment().minute(0).hour(22))}
        min={new Date(moment().minute(0).hour(7))}
        onEventDrop={moveEvent}
        onSelectEvent={selectEvent}
        onSelectSlot={onSelectSlot}
        selectable
        style={{ height: '24em' }}
        value={{ values: events }}
        views={['month']}
      />
    </Wrapper>
  )
}
DataFormJsonCalendar.defaultProps = {
  values: {},
  handleChange: () => null,
}
DataFormJsonCalendar.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      end: PropTypes.string,
      imageUrl: PropTypes.string, // null
      start: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func,
}

export default DataFormJsonCalendar
