// src/utils/constants/paths.js

export default {
  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_PROFILE: '/auth/profile',
  AUTH_SIGNUP: '/auth/signup',
  CONTACT: '/contact',
  DATA: '/data',
  DATA_SHOW: '/data/show',
  DATA_LIST: '/data/list',
  DEFAULT: '/',
  FORBIDDEN: '/unauthorized',
  HOME: '/home',
  USER: '/user',
  USER_PROFILE: '/user/profile',
}
