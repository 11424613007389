// src/constants/datas.js

export default {
  CATEGORY: 'category',
  FEATURE: 'feature',
  HOMECAROUSEL: 'homecarousel',
  HOMENEWS: 'homenews',
  HOMEMESSAGE: 'homemessage',
  HOMEVIDEOS: 'homevideos',
  SECTION: 'section',
  SERVICE_GM: 'serviceGM',
  SERVICE_GM_AREA: 'ServiceGMArea',
  SERVICE_GM_SECTOR: 'ServiceGMSector',
  SERVICE_GM_DIRECTION: 'ServiceGMDirection',
  SERVICE_GM_SUMMARY: 'ServiceGMSummary',
  TYPE: 'type',
  USER: 'user',
}
