import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { STRING, TEXTAREA_WYSIWYG } = constants.FIELD_TYPES
const FIELDS = `
    id
    content
    isActive
`

/**
 *
 * DefaultHomenews
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultHomenews extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.content = this.content || ''
  }

  static hasSingleItem() {
    return true
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'content', fieldType: TEXTAREA_WYSIWYG },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getHomeNews',
        request: gql` query getHomeNews($id: ID!) {
          getHomeNews(id: $id) {
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allHomeNews',
        request: gql` query allHomeNews {
          allHomeNews {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postHomeNews($input: HomeNewsInput!) {
            postHomeNews(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.content !== ''].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        content: this.content,
        id: this.id,
        isActive: true,
      },
    }
  }

  purge() {
    return this
  }
}

export default DefaultHomenews
