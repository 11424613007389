import React from 'react'
import PropTypes from 'prop-types'

import Fieldset from '~components/Fieldset'

/**
 * AuthForm
 * @component
 *
 */
function AuthFormGroup(props) {
  const { legend, ...rest } = props
  return <Fieldset legend={legend} {...rest} />
}
AuthFormGroup.defaultProps = {
  legend: '',
}
AuthFormGroup.propTypes = {
  legend: PropTypes.string,
}

export default AuthFormGroup
