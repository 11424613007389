import React from 'react'
import PropTypes from 'prop-types'
import BodyContainer from '~Layout/containers/BodyContainer'
import ContentContainer from '~Layout/containers/ContentContainer'
import FooterContainer from '~Layout/containers/FooterContainer'
import HeaderContainer from '~Layout/containers/HeaderContainer'
import ModalContainer from '~Layout/containers/ModalContainer'

/**
 * Layout
 * @component
 *
 */
function Layout(props) {
  const { children } = props
  return (
    <>
      <BodyContainer>
        <HeaderContainer />
        <ContentContainer>{children}</ContentContainer>
        <FooterContainer />
      </BodyContainer>
      <ModalContainer />
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
