import { gql } from '@apollo/client'

import constants from '~utils/constants'

import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const {
  BOOLEAN,
  IMAGE,
  // SELECT,
  STRING,
} = constants.FIELD_TYPES

const FIELDS = `
  accessToken
  createdAt
  credentials
  email
  extra
  file {id url}
  id
  isVerified
  lastname
  name
  token
  updatedAt
`

/**
 *
 * DefaultUser
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultUser extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.email = obj.email || ''
    this.file = obj.file || null
    this.isVerified = obj.isVerified || false
    this.lastname = obj.lastname || ''
    this.name = obj.name || ''
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
      { fieldName: 'lastname', fieldType: STRING },
      { fieldName: 'email', fieldType: STRING },
      { fieldName: 'isVerified', fieldType: BOOLEAN },
      { fieldName: 'file', fieldType: IMAGE },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getUser',
        request: gql` query User ($id: ID!){
          getUser (id: $id){
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allUsers',
        request: gql` query allUsers {
          allUsers {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postUser($input: UserInput!) {
            postUser(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [
      this.name !== '',
      this.file,
      this.lastname,
      this.email,
      false,
    ].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        file: this.file instanceof File ? this.file : null,
        id: this.id,
        name: this.name,
        email: this.email,
        lastname: this.lastname,
        isVerified: this.isVerified,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultUser
