import React from 'react'
import constants from '~utils/constants'

const { AUTH_TOKEN } = constants

const UserContext = React.createContext()

/**
 *
 * UserProvider
 * @component
 * @context
 * @see module/Auth to see the Auth implementation
 *
 */
function UserProvider(props) {
  const [user, setUser] = React.useState({})
  const value = {
    user,
    isAuthenticated: !!user.token,
    hasAccessToken: !!localStorage.getItem(AUTH_TOKEN),
    logout() {
      localStorage.removeItem(AUTH_TOKEN)
      setUser({})
    },
    updateUser({ user: nextUser, accessToken }) {
      if (accessToken) localStorage.setItem(AUTH_TOKEN, accessToken)
      setUser(nextUser)
    },
  }

  return <UserContext.Provider {...props} value={value} />
}

/**
 * useUser
 * @context
 * @return context
 *
 */
export const useUser = () => {
  const context = React.useContext(UserContext)
  if (!context) throw new Error('UserContext must be called in UserProvider')
  return context
}

export default UserProvider
