import React from 'react'
import HomeContainer from '~Home/containers/HomeContainer'

/**
 * Home
 * @component
 *
 */
function Home(props) {
  return <HomeContainer {...props} />
}

export default Home
