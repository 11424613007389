import React from 'react'
// import PropTypes from 'prop-types'

import Title from '~components/Title'

/**
 * UserTitle
 * @component
 *
 */
function UserTitle(props) {
  return <Title {...props} />
}
UserTitle.defaultProps = {}
UserTitle.propTypes = {}

export default UserTitle
