import React from 'react'
import styled from 'styled-components'
import BaseWrapper from '~components/Wrapper'

const Wrapper = styled(BaseWrapper)`
  color: ${({ theme }) => theme.COLORS.FOREGROUND};
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    -45deg,
    ${({ theme }) => theme.COLORS.PRIMARY},
    transparent
  );
  box-shadow: 0px -1px 1px -1px #000000b3 inset;
`

/**
 * WrapperHeader
 * @component
 *
 */
function WrapperHeader(props) {
  return <Wrapper {...props} />
}

export default WrapperHeader
