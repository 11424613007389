// src/utils/functions/checkEmail

/**
 * checkEmail
 * @param {String} text
 * @return Boolean :: the result of the text
 *
 */
function checkEmail(text) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(text)
}

export default checkEmail
