import React from 'react'
import PropTypes from 'prop-types'
import Loading from '~components/Loading'

const MESSAGES = {
  LOGIN: 'Authentication attempt in progress...',
  SIGNUP: 'Registration in progress ...',
}

/**
 * AuthLoading
 * @component
 *
 */
function AuthLoading(props) {
  const { isLogin, ...rest } = props
  const message = isLogin ? MESSAGES.LOGIN : MESSAGES.SIGNUP
  return <Loading {...rest} message={message} />
}
AuthLoading.defaultProps = {
  isLogin: false,
}
AuthLoading.propTypes = {
  isLogin: PropTypes.bool,
}

export default AuthLoading
