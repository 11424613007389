import React from 'react'
import PropTypes from 'prop-types'
import Link from '~components/Link'
import ListItem from '~components/ListItem'

import constants from '~utils/constants'

const { PRIMARY, TERTIARY } = constants.STYLES

/**
 * SidebarItem
 * @component
 *
 */
function SidebarItem(props) {
  const { children, isActive, ...restProps } = props
  const color = isActive ? PRIMARY : TERTIARY
  return (
    <ListItem>
      <Link color={color} block size="1em" {...restProps}>
        {children}
      </Link>
    </ListItem>
  )
}
SidebarItem.defaultProps = {
  isActive: false,
}
SidebarItem.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

export default SidebarItem
