import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import constants from '~utils/constants'

import AuthModule from '~Auth'
import DataModule from '~Data'
import ErrorHandlerModule, { NotFound, NotAllowed } from '~ErrorHandler'
import HomeModule from '~Home'
import LayoutModule from '~Layout'
import SidebarModule from '~Sidebar'
import UserModule from '~User'

const { DEFAULT, DATA, USER, FORBIDDEN } = constants.PATHS

/**
 *
 * App
 * desc::
 * modules that start by uppercase ( example ~Auth) are located at src/modules
 */
function App() {
  return (
    <Router>
      <ErrorHandlerModule>
        <AuthModule>
          <LayoutModule>
            <SidebarModule />
            <Switch>
              <Route exact path={DEFAULT} component={HomeModule} />
              <Route path={DATA} component={DataModule} />
              <Route path={USER} component={UserModule} />
              <Route path={FORBIDDEN} component={NotAllowed} />
              <Route component={NotFound} />
            </Switch>
          </LayoutModule>
        </AuthModule>
      </ErrorHandlerModule>
    </Router>
  )
}

export default App
