import React from 'react'
// import PropTypes from 'prop-types'

import Modal from '~components/Modal'

/**
 * LayoutModal
 * @component
 *
 */
function LayoutModal(props) {
  return <Modal {...props} />
}
LayoutModal.defaultProps = {}
LayoutModal.propTypes = {}

export default LayoutModal
