import React from 'react'
import PropTypes from 'prop-types'

import Input from '~components/Input'
import Label from '~components/Label'
import Wysiwyg from '~components/Wysiwyg'

/**
 * DataFormInput
 * @component
 *
 */
function DataFormInput(props) {
  const {
    handleChange,
    isCheckbox,
    isLongtext,
    isNumber,
    isSelect,
    isWysiwyg,
    label,
    value,
    ...rest
  } = props
  const updateValue = (nextValue) => {
    handleChange(nextValue)
  }
  if (isWysiwyg) {
    return (
      <Label>
        {label && `${label}:`}
        <Wysiwyg value={value} onChange={updateValue} />
      </Label>
    )
  }
  return (
    <Label>
      {label && `${label}:`}
      <Input
        {...rest}
        isLongtext={isLongtext}
        isNumber={isNumber}
        isCheckbox={isCheckbox}
        onChange={updateValue}
        width="30em"
        value={value}
      />
    </Label>
  )
}
DataFormInput.defaultProps = {
  file: false,
  handleChange: () => null,
  isCheckbox: false,
  isLongtext: false,
  isNumber: false,
  isSelect: false,
  isWysiwyg: false,
  label: '',
  value: '',
}
DataFormInput.propTypes = {
  file: PropTypes.bool,
  handleChange: PropTypes.func,
  isCheckbox: PropTypes.bool,
  isLongtext: PropTypes.bool,
  isNumber: PropTypes.bool,
  isSelect: PropTypes.bool,
  isWysiwyg: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
}

export default DataFormInput
