import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '~contexts/ThemeContext'
import Link from '~components/Link'
import constants from '~utils/constants'

const { STYLES } = constants

/**
 * ErrorLink
 * @component
 *
 */
function ErrorLink(props) {
  const { colors } = useTheme()
  const { TERTIARY } = colors
  const { children, to, ...rest } = props
  const linkProps = {
    backgroundcolor: TERTIARY,
    color: STYLES.BACKGROUND,
    size: '0.8em',
  }
  return (
    <Link to={to} boxed {...rest} {...linkProps}>
      {children}
    </Link>
  )
}
ErrorLink.defaultProps = {}
ErrorLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default ErrorLink
