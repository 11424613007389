import React from 'react'
// import PropTypes from 'prop-types'

import Title from '~components/Title'

/**
 * AuthTitle
 * @component
 *
 */
function AuthTitle(props) {
  return <Title {...props} />
}
AuthTitle.defaultProps = {}
AuthTitle.propTypes = {}

export default AuthTitle
