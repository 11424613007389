import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseWrapper from '~components/Wrapper'

/**
 * Wrapper
 * @component
 *
 */
const Wrapper = styled(BaseWrapper)`
  color: ${({ theme }) => theme.COLORS.FOREGROUND};
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

/**
 * WrapperSidebar
 * @component
 *
 */
function WrapperSidebar(props) {
  const { children } = props
  return <Wrapper>{children}</Wrapper>
}
WrapperSidebar.defaultProps = {
  children: undefined,
}
WrapperSidebar.propTypes = {
  children: PropTypes.node,
}

export default WrapperSidebar
