import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { STRING } = constants.FIELD_TYPES
const FIELDS = `
    id
    name
    serviceGMAreas { name }
    owners{ name }
`

/**
 *
 * DefaultServiceGMSector
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultServiceGMSector extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.name = this.name || ''
  }

  static getLabel() {
    return 'Service: Groupe de Maison (Secteurs)'
  }

  static hasSingleItem() {
    return false
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getServiceGMSector',
        request: gql` query getServiceGMSector($id: ID!) {
          getServiceGMSector(id: $id) {
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allServiceGMSector',
        request: gql` query allServiceGMSector {
          allServiceGMSector {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postServiceGMSector($input: ServiceGMSectorInput!) {
            postServiceGMSector(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.name !== ''].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        id: this.id,
        name: this.name,
      },
    }
  }

  purge() {
    return this
  }
}

export default DefaultServiceGMSector
