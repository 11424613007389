// src/utils/constants/alerts

export default {
  DEFAULT: {
    message: '',
    title: '',
    type: 'info',
  },
  AUTH_INCORECT_EMAIL: {
    message: 'Incorrect Email format',
    title: '',
    type: 'warning',
  },
  AUTH_SERVER_ERRORS: {
    message: 'Authentication error',
    title: '',
    type: 'danger',
  },
  AUTH_INCORECT_PASSWORD: {
    message:
      'Password must have at least one uppercase, one lowercase, one numeric character, one special character and at least 8 characters ',
    title: '',
    type: 'warning',
  },
  IS_MISSING: {
    message: 'Some required fields are missing',
    title: '',
    type: 'danger',
  },
  IS_INCORRECT: {
    message: 'Incorrect Ids',
    title: '',
    type: 'danger',
  },
  IS_LOADING: {
    message: 'Loading ...',
    title: '',
    type: 'info',
  },
  POST_DATA: {
    message: 'posting data  ...',
    title: '',
    type: 'info',
  },
  POST_DATA_DONE: {
    message: 'Data posted successfully',
    title: '',
    type: 'success',
  },
  REMOVE_DATA: {
    message: 'removing data  ...',
    title: '',
    type: 'info',
  },
  REMOVE_DATA_DONE: {
    message: 'Data removed successfully',
    title: '',
    type: 'success',
  },
}
