import React from 'react'
import PropTypes from 'prop-types'
import Link from '~components/Link'

import constants from '~utils/constants'

const { PRIMARY, QUATERNARY } = constants.STYLES

/**
 * LayoutLink
 * @component
 *
 */
function LayoutLink(props) {
  const { children, isActive, ...restProps } = props
  const color = isActive ? PRIMARY : QUATERNARY
  return (
    <Link color={color} boxed {...restProps}>
      {children}
    </Link>
  )
}
LayoutLink.defaultProps = {
  isActive: false,
}
LayoutLink.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

export default LayoutLink
