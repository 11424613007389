import React from 'react'
import PropTypes from 'prop-types'

import Form from '~components/Form'
import Button from '~components/Button'
import Link from '~components/Link'
import Fieldset from '~components/Fieldset'

import constants from '~utils/constants'

const { INFO, WARNING } = constants.STATUS

/**
 * DataForm
 * @component
 *
 */
function DataForm(props) {
  const { children, action, cancelUri, isValid, ...rest } = props
  return (
    <Form {...rest}>
      {children}
      <Fieldset center>
        <Link color={INFO} to={cancelUri} boxed>
          Cancel
        </Link>
        <Button color={WARNING} onClick={action} disabled={!isValid}>
          Save
        </Button>
      </Fieldset>
    </Form>
  )
}
DataForm.defaultProps = {
  action: () => null,
  cancelUri: '/',
  children: undefined,
  isValid: false,
}
DataForm.propTypes = {
  action: PropTypes.func,
  cancelUri: PropTypes.string,
  children: PropTypes.node,
  isValid: PropTypes.bool,
}

export default DataForm
