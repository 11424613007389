// src/utils/fieldTypes

export default {
  BOOLEAN: 'boolean',
  CHECKBOX: 'checkbox',
  COLOR: 'color',
  IMAGE: 'image',
  JSON: 'json',
  JSON_CALENDAR: 'jsonCalendar',
  JSON_DIRECT: 'jsonirect',
  JSON_GOOGLEMAP: 'jsonoglemap',
  JSON_MULTIPLE_VIDEOS: 'jsonMultiplevideos',
  JSON_SINGLE_VIDEO: 'jsonSinglevideo',
  NUMBER: 'number',
  SELECT: 'select',
  STRING: 'string',
  TEXTAREA: 'textarea',
  TEXTAREA_WYSIWYG: 'wysiwyg',
}
