import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'

const { STRING, NUMBER, BOOLEAN } = constants.FIELD_TYPES
const FIELDS = `
  id
  link
  name
  order
  visible
`

/**
 *
 * DefaultHomeVideo
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultHomeVideo extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.name = obj.name || ''
    this.link = obj.link || ''
    this.order = obj.position || obj.order || -1
    this.visible = obj.visible || false
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'order', fieldType: NUMBER },
      { fieldName: 'name', fieldType: STRING },
      { fieldName: 'link', fieldType: STRING },
      { fieldName: 'visible', fieldType: BOOLEAN },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getHomeVideo',
        request: gql` query HomeVideo ($id: ID!){
          getHomeVideo (id: $id){
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allHomeVideos',
        request: gql` query allHomeVideos {
          allHomeVideos {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postHomeVideo($input: HomeVideoInput!) {
            postHomeVideo(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: gql`
          mutation removeHomeVideo($id: ID!) {
            removeHomeVideo(id: $id) {
              name
            }
          }
        `,
      },
    }
  }

  isValid() {
    return [this.name !== '', this.link !== '', this.order >= 0].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        name: this.name,
        link: this.link,
        order: parseInt(this.order, 10),
        id: this.id,
        visible: this.visible,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultHomeVideo
