import { gql } from '@apollo/client'

import constants from '~utils/constants'

import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { COLOR, STRING, NUMBER, IMAGE } = constants.FIELD_TYPES

const FIELDS = `
  id
  name
  color
  order
  file {
    id
    url
  }
`

/**
 *
 * DefaultCategory
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultCategory extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.order = this.order || -1
    this.name = this.name || ''
    this.color = this.color || '#fff'
    this.file = this.file || null
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'order', fieldType: NUMBER },
      { fieldName: 'name', fieldType: STRING },
      { fieldName: 'color', fieldType: COLOR },
      { fieldName: 'file', fieldType: IMAGE },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getCategory',
        request: gql` query Category ($id: ID!){
          getCategory (id: $id){
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allCategories',
        request: gql` query allcategories {
          allCategories {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postCategory($input: CategoryInput!) {
            postCategory(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.name !== '', this.color, this.file, this.order >= 0].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        name: this.name,
        color: this.color,
        order: parseInt(this.order, 10),
        file: this.file instanceof File ? this.file : null,
        id: this.id,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultCategory
