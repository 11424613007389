import { Link } from 'react-router-dom'
import styled from 'styled-components'

/**
 * BaseLink
 * @component
 *
 */
const BaseLink = styled(Link).attrs((props) => {
  const { className, color, theme } = props
  const themeColor = theme.COLORS[color.toUpperCase()]
  const style = {}
  if (className.includes('boxed') || className.includes('block')) {
    style.backgroundColor = themeColor
  }
  return { style }
})`
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
  margin: 0 4px;
  color: ${({ theme }) => theme.COLORS.FOREGROUND};
  font-size: ${({ size }) => size || '1em'};
  &:disabled {
    opacity: 0.6;
    cursor: text;
  }
  &.boxed {
    font-weight: 600;
    color: ${({ theme }) => theme.COLORS.FOREGROUND};
    background-color: ${({ theme }) => theme.COLORS.QUATERNARY};
    border-radius: 4px;
    box-shadow: 1px 1px 2px -1px;
    max-width: 8em;
  }
  &.block {
    display: block;
  }
  @media (min-width: 768px) {
    padding: 8px;
  }
`

export default BaseLink
