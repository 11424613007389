import styled from 'styled-components'

/**
 * BaseListItem
 * @component
 *
 */
const BaseListItem = styled.li``

export default BaseListItem
