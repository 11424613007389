import React from 'react'
import styled from 'styled-components'
import BaseWrapper from '~components/Wrapper'

const Wrapper = styled(BaseWrapper)`
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.COLORS.TERTIARY};
`

/**
 * WrapperBody
 * @component
 *
 */
function WrapperBody(props) {
  return <Wrapper {...props} />
}

export default WrapperBody
