import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'

const { STRING, NUMBER, IMAGE, TEXTAREA } = constants.FIELD_TYPES
const FIELDS = `
  id
  description
  order
  file {
    id
    url
  }
`

/**
 * DefaultHomecarousel
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 */
class DefaultHomecarousel extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.order = this.order || -1
    this.description = this.description || ''
    this.file = this.file || null
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'order', fieldType: NUMBER },
      { fieldName: 'description', fieldType: TEXTAREA },
      { fieldName: 'file', fieldType: IMAGE },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getHomeCarousel',
        request: gql` query HomeCarousel($id: ID!) {
          getHomeCarousel(id: $id) {
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allHomeCarousels',
        request: gql` query allHomeCarousels {
          allHomeCarousels {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postHomeCarousel($input: HomeCarouselInput!) {
            postHomeCarousel(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: gql`
          mutation removeHomeCarousel($id: ID!) {
            removeHomeCarousel(id: $id) {
              order
            }
          }
        `,
      },
    }
  }

  isValid() {
    return [this.description !== '', this.file, this.order >= 0].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        description: this.description,
        order: parseInt(this.order, 10),
        file: this.file instanceof File ? this.file : null,
        id: this.id,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultHomecarousel
