/* eslint-disable class-methods-use-this */

/**
 * DataInterface
 * @interface
 * @desc :: interface for models, it provides the isValid function
 */
class DataInterface {
  constructor(object) {
    if (this.constructor === DataInterface) {
      throw new TypeError(
        'Abstract class "DataInterface" cannot be instantiated directly',
      )
    }
    this.hydrate(object)
  }

  /**
   * hydrate
   * @desc :: hydrate instance values in the constructor
   *
   */
  hydrate(obj) {
    if (!(obj instanceof Object)) return
    Object.keys(obj).forEach((i) => {
      this[i] = obj[i]
    })
  }

  static getLabel() {
    return ''
  }

  /**
   * hasOneItem
   * @desc :: check whether the table can only have one item
   * it allows to skip the ListContainer and go directly to the
   * DetailContainer
   *
   */
  static hasSingleItem() {
    return false
  }

  /**
   *
   * fields
   * @desc :: get fields names of the data, manage how we can display data
   * @params :: {Object} options
   *            options.skip:: {Array of String} fields to be skiped
   *
   * @return {Array} [
   *   {
   *      fieldName: {String},
   *      fieldTypes: {String},
   *      (optional) options: {Array}
   *    },
   *    ...
   * ]
   *
   */
  static fields() {
    throw new Error(
      `You musto implement the function "fields" in the constructor "${this.constructor.name}"`,
    )
  }

  /**
   * getQueries
   * @abstract
   * @desc :: get the queries (for example in graphQL) in a SPECIAL order:
   * @return Object {
   *    {Object} one:: name and request,
   *    {Object} all:: name and request,
   *    {Object} post :: name and request,
   *    {Object} remove :: name and request
   * }
   *
   */
  static getQueries() {
    throw new Error(
      `You must implement the function "getQueries" in the constructor "${this.constructor.name}"`,
    )
  }

  /**
   * isValid
   * @abstract
   * @desc :: check if value is in correct format
   * @return Boolean
   *
   */
  isValid() {
    throw new Error(
      `You must implement the function "isValid" in the constructor "${this.constructor.name}"`,
    )
  }

  /**
   * parse
   * @abstract
   * @desc :: parse the data for the Server
   * @return Object
   *
   */
  parse() {
    throw new Error(
      `You must implement the function "parse" in the constructor "${this.constructor.name}"`,
    )
  }

  /**
   * purge
   * @abstract
   * @desc :: purge the data for the Server
   * @return Object
   *
   */
  purge() {
    throw new Error(
      `You must implement the function "purge" in the constructor "${this.constructor.name}"`,
    )
  }
}

export default DataInterface
