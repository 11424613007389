import React from 'react'
import ApolloProvider from './apollo'

/**
 * GraphQLProvider
 * @provider
 *
 */
function GraphQLProvider(props) {
  return <ApolloProvider {...props} />
}

export default GraphQLProvider
