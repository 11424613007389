// src/utils/functions/stripTags

/**
 * stripTag
 *
 */
function stripTags(text) {
  return text.replace(/(<([^>]+)>)/gi, '')
}

export default stripTags
