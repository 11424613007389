import React from 'react'
// import PropTypes from 'prop-types';

import { useUser } from '~contexts/UserContext'
import useAlert from '~hooks/useAlert'
import DefaultUser from '~utils/constructors/DefaultUser'

import UserButton from '~User/components/UserButton'
import UserForm from '~User/components/UserForm'
import UserInput from '~User/components/UserInput'
import UserTitle from '~User/components/UserTitle'
import WrapperUser from '~User/components/WrapperUser'

/**
 * ProfileContainer
 * @component
 *
 */
function ProfileContainer() {
  const { updateUser, user } = useUser()
  const { AlertBlock, setAlert, alerts } = useAlert()
  const [tempUser, setTempUser] = React.useState(user)
  const [isEditMode, setEditState] = React.useState(false)

  const changeMode = () => setEditState(!isEditMode)
  const setValue = (field, value) =>
    setTempUser(new DefaultUser({ ...tempUser, [field]: value }))
  const disabledProps = isEditMode ? {} : { disabled: true }
  const update = () => {
    if (Object.values(tempUser).some((v) => v === '')) {
      return setAlert(alerts.IS_MISSING)
    }
    setAlert(alerts.IS_LOADING)
    return updateUser({ user: tempUser })
  }

  return (
    <WrapperUser>
      <div className="row">
        <UserTitle>Profile</UserTitle>
      </div>
      <AlertBlock />
      <UserForm>
        <label>
          <span>Username:</span>
          <UserInput
            value={tempUser.username}
            onChange={(e) => setValue('username', e.target.value)}
            placeholder="username"
            {...disabledProps}
          />
        </label>
        {isEditMode && (
          <label>
            <span>Password:</span>
            <UserInput
              value={tempUser.password}
              onChange={(e) => setValue('password', e.target.value)}
              type="password"
              placeholder="password"
            />
          </label>
        )}
        <UserButton className="center" onClick={update} success>
          Validate
        </UserButton>
      </UserForm>
      <UserButton className="center" onClick={changeMode}>
        {isEditMode ? 'Cancel' : 'Edit'}
      </UserButton>
    </WrapperUser>
  )
}
ProfileContainer.propTypes = {}

export default ProfileContainer
