import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { FIELD_TYPES, FEATURES } = constants
const { STRING, SELECT, NUMBER } = FIELD_TYPES

const FIELDS = `
  id
  data
  name
  nature
  position
`

/**
 * DefaultFeature
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 */
class DefaultFeature extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    const { data = {}, nature = {} } = obj
    this.data = typeof data === 'string' ? JSON.parse(data) : data
    this.name = obj.name || ''
    this.nature = {
      key: (typeof nature === 'string' && nature) || nature.key,
      title: (typeof nature === 'string' && nature) || nature.key,
    }
    this.position = obj.position || -1
  }

  static fields(options = {}) {
    const { skip = [], item = {} } = options
    const { nature } = item
    const natureKey = nature && nature.key
    let dataFieldType = ''
    switch (natureKey) {
      case FEATURES.CALENDAR:
        dataFieldType = FIELD_TYPES.JSON_CALENDAR
        break
      case FEATURES.DIRECT:
        dataFieldType = FIELD_TYPES.JSON_DIRECT
        break
      case FEATURES.GOOGLEMAP:
        dataFieldType = FIELD_TYPES.JSON_GOOGLEMAP
        break
      case FEATURES.MULTIPLE_VIDEOS:
        dataFieldType = FIELD_TYPES.JSON_MULTIPLE_VIDEOS
        break
      case FEATURES.SINGLE_VIDEO:
        dataFieldType = FIELD_TYPES.JSON_SINGLE_VIDEO
        break
      default:
        dataFieldType = FIELD_TYPES.JSON
    }
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'position', fieldType: NUMBER },
      { fieldName: 'name', fieldType: STRING },
      {
        fieldName: 'nature',
        fieldType: SELECT,
        options: Object.values(FEATURES).map((feature) => ({
          title: feature,
          key: feature,
        })),
      },
      { fieldName: 'data', fieldType: dataFieldType },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getFeature',
        request: gql` query Feature ($id: ID!){
          getFeature (id: $id){
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allFeatures',
        request: gql` query allFeatures {
          allFeatures {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postFeature($input: FeatureInput!) {
            postFeature(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.name !== '', this.nature !== '', this.position >= 0].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        name: this.name,
        nature: this.nature.key,
        position: parseInt(this.position, 10),
        data: JSON.stringify(this.data),
        id: this.id,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultFeature
