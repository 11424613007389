import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Link from '~components/Link'
import Title from '~components/Title'
import Wrapper from '~components/Wrapper'

import constants from '~utils/constants'

const { INFO } = constants.STATUS

/**
 * CategoryWrapperList
 * @component
 *
 */
const BaseWrapperList = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  justify-content: flex-start;
  min-height: 5em;
  padding: 2em;
  label {
    margin-bottom: 8px;
  }
`

/**
 * WrapperList
 * @component
 *
 */
function WrapperList(props) {
  const { add, children, title, ...rest } = props

  return (
    <BaseWrapperList {...rest}>
      {title && <Title left>{title}</Title>}
      <p>
        {add && (
          <Link color={INFO} boxed to={add}>
            Add new item (+)
          </Link>
        )}
      </p>
      {children}
    </BaseWrapperList>
  )
}
WrapperList.defaultProps = {
  add: '',
  children: undefined,
  title: '',
}

WrapperList.propTypes = {
  add: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
}

export default WrapperList
