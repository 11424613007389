import React from 'react'
import PropTypes from 'prop-types'
import { colorsThemesList } from '~contexts/ThemeContext'
import { withAsyncErrorHandling } from '~contexts/ErrorContext'
import BaseLink from './style'

/**
 * Link
 * @component
 *
 */
function Link(props) {
  const { href, boxed, color, block, to, ...rest } = props

  const styledProps = { ...rest, color }
  if (boxed) styledProps.className = `${styledProps.className} boxed`
  if (block) styledProps.className = `${styledProps.className} block`

  return <BaseLink {...styledProps} to={to || href} />
}
Link.defaultProps = {
  boxed: false,
  className: '',
  color: colorsThemesList[0],
  href: '',
  block: false,
  size: '',
  to: '',
}
Link.propTypes = {
  block: PropTypes.bool,
  boxed: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(colorsThemesList),
  href: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
}

export default withAsyncErrorHandling(Link)
