import React from 'react'
import styled from 'styled-components'
import BaseWrapper from '~components/Wrapper'

/**
 * Wrapper
 * @component
 *
 */
const Wrapper = styled(BaseWrapper)`
  width: 100%;
  background: linear-gradient(
    -45deg,
    ${({ theme }) => theme.COLORS.PRIMARY},
    transparent
  );
  box-shadow: 0px 1px 1px -1px ${({ theme }) => theme.COLORS.FOREGROUND} inset;
  display: flex;
  justify-content: center;
  align-items: center;
`

/**
 * WrapperFooter
 * @component
 *
 */
function WrapperFooter(props) {
  return <Wrapper {...props} />
}

export default WrapperFooter
