import React from 'react'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import constants from '~utils/constants'

const { AUTH_TOKEN } = constants

const link = createUploadLink({
  uri: process.env.REACT_APP_BACK_URI,
  headers: {
    authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
  },
})

/**
 *
 * ConfiguredAppolloProvider
 * @provider
 *
 */
function ConfiguredApolloProvider(props) {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })
  return <ApolloProvider client={client} {...props} />
}

export default ConfiguredApolloProvider
