import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { useModal } from '~contexts/ModalContext'
import useAlert from '~hooks/useAlert'
import constants from '~utils/constants'
import strUcFirst from '~utils/functions/strUcFirst'

import useDataRequest from '~Data/hooks/useDataRequest'

import DataForm from '~Data/components/DataForm'
import DataFormGroup from '~Data/components/DataFormGroup'
import DataFormImage from '~Data/components/DataFormImage'
import DataFormInput from '~Data/components/DataFormInput'
import DataFormSelect from '~Data/components/DataFormSelect'
import DataFormJsonCalendar from '~Data/components/DataFormJsonCalendar'
import DataFormJsonvideos from '~Data/components/DataFormJsonvideos'
import DataModalAction from '~Data/components/DataModalAction'
import DataRequestManager from '~Data/components/DataRequestManager'
import WrapperData from '~Data/components/WrapperData'

import ItemFactory from '~Data/constructors/factory/ItemFactory'

const { FIELD_TYPES, PATHS, WORDS } = constants
const { BOOLEAN, NUMBER, STRING, TEXTAREA, TEXTAREA_WYSIWYG } = FIELD_TYPES

/**
 *
 * DetailsContainer
 * @container
 *
 *
 */
function DetailsContainer(props) {
  const { match } = props
  const history = useHistory()
  const { id, dataType } = match.params
  const isAddMode = id === 'add'
  const DefaultDataType = ItemFactory(dataType)
  const queries = DefaultDataType.getQueries()
  const [item, hydrateItem] = React.useState(new DefaultDataType())
  const [selectList, setSelectList] = React.useState([])
  const [tmpImg, setTmpImg] = React.useState('')

  const { AlertBlock, setAlert, alerts } = useAlert()
  const { activeModal, closeModal } = useModal()
  const { loading, error, data, postData } = useDataRequest({
    get: queries.one.request,
    post: queries.post.request,
    options: { variables: { id } },
  })
  const dataListURL = `${PATHS.DATA_LIST}/${dataType}`
  const sendPostRequest = () => {
    setAlert(alerts.POST_DATA)
    postData({ variables: item.parse() }).then(() => {
      setAlert(alerts.POST_DATA_DONE)
      setTimeout(history.push(dataListURL), 2000)
    })
  }

  const setItem = (obj) => hydrateItem(new DefaultDataType(obj))
  const setValue = (field, val) => {
    setItem(new DefaultDataType({ ...item, [field]: val }))
  }
  const setImg = (file, srcImg) => {
    setItem(new DefaultDataType({ ...item, file }))
    setTmpImg(srcImg)
  }

  const askForPost = () => {
    activeModal(
      <DataModalAction
        closeModal={closeModal}
        onValidate={() => sendPostRequest()}
        warning={!isAddMode}
        subject={WORDS.CONFIRM_POST}
      />,
    )
  }

  React.useEffect(() => {
    const { name, selectNamesList = [] } = queries.one
    if (data) {
      const nextData = data[name] || {}
      setItem(nextData)
      setSelectList(selectNamesList.map((selectName) => data[selectName]))
    }
    return () => item.purge()
  }, [data])
  return (
    <DataRequestManager isRequestLoading={loading} errorRequest={error}>
      <WrapperData title={DefaultDataType.getLabel() || strUcFirst(dataType)}>
        <AlertBlock />
        <DataForm
          action={askForPost}
          isValid={item.isValid()}
          cancelUri={dataListURL}>
          {DefaultDataType.fields({ item, selectList }).map(
            ({ fieldName, fieldType, options }) => (
              <DataFormGroup legend={strUcFirst(fieldName)} key={fieldName}>
                {fieldType === FIELD_TYPES.IMAGE && (
                  <DataFormImage
                    src={tmpImg || (item[fieldName] && item[fieldName].url)}
                    tmpSrc={item.srcImg}
                    handleChange={(file, srcImg) => setImg(file, srcImg)}
                  />
                )}
                {fieldType === FIELD_TYPES.JSON_MULTIPLE_VIDEOS && (
                  <DataFormJsonvideos
                    handleChange={(v) => setValue(fieldName, v)}
                    values={item[fieldName] && item[fieldName].values}
                  />
                )}
                {fieldType === FIELD_TYPES.JSON_CALENDAR && (
                  <DataFormJsonCalendar
                    handleChange={(v) => setValue(fieldName, v)}
                    values={item[fieldName] && item[fieldName].values}
                  />
                )}
                {fieldType === FIELD_TYPES.SELECT && (
                  <DataFormSelect
                    handleChange={(v) => setValue(fieldName, v)}
                    options={options}
                    value={item[fieldName].key}
                  />
                )}
                {[BOOLEAN, NUMBER, STRING, TEXTAREA, TEXTAREA_WYSIWYG].includes(
                  fieldType,
                ) && (
                  <DataFormInput
                    disabled={['id'].includes(fieldName)}
                    handleChange={(v) => setValue(fieldName, v)}
                    isCheckbox={fieldType === FIELD_TYPES.BOOLEAN}
                    isLongtext={fieldType === FIELD_TYPES.TEXTAREA}
                    isNumber={fieldType === FIELD_TYPES.NUMBER}
                    isWysiwyg={fieldType === FIELD_TYPES.TEXTAREA_WYSIWYG}
                    value={item[fieldName]}
                  />
                )}
              </DataFormGroup>
            ),
          )}
        </DataForm>
      </WrapperData>
    </DataRequestManager>
  )
}
DetailsContainer.defaultProps = {}
DetailsContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default DetailsContainer
