import React from 'react'
import PropTypes from 'prop-types'

import constants from '~utils/constants'

import Input from '~components/Input'
import Label from '~components/Label'

const { SUCCESS, DANGER } = constants.STATUS

/**
 * AuthInput
 * @component
 *
 */
function AuthInput(props) {
  const { checkValid, handleChange, label, value, ...rest } = props
  const updateValue = (nextValue) => {
    handleChange(nextValue)
  }
  const addedStyle = {}
  if (value !== '') addedStyle.color = checkValid(value) ? SUCCESS : DANGER
  return (
    <Label>
      {label && `${label}:`}
      <Input
        {...addedStyle}
        {...rest}
        placeholder={label}
        onChange={updateValue}
        value={value}
        width="25em"
      />
    </Label>
  )
}
AuthInput.defaultProps = {
  checkValid: () => true,
  handleChange: () => null,
  label: '',
  value: '',
}
AuthInput.propTypes = {
  checkValid: PropTypes.func,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
}

export default AuthInput
