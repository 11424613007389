import React from 'react'

import AuthenticationContainer from '~Auth/containers/AuthenticationContainer'

/**
 * Auth
 * @component
 *
 */
function Auth(props) {
  return <AuthenticationContainer {...props} />
}
Auth.defaultProps = {}
Auth.propTypes = {}

export default Auth
