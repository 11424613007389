import React from 'react'
import PropTypes from 'prop-types'

import TableRowItem from '~components/TableRowItem'
import Link from '~components/Link'
import Button from '~components/Button'
import Thumbnail from '~components/Thumbnail'

import constants from '~utils/constants'
import { graphCoolAdapter, stripTags } from '~utils/functions'

const { DANGER, INFO } = constants.STATUS

/**
 * DataTableCase
 * @component
 *
 */
function DataTableCase(props) {
  const {
    action,
    askDelete,
    handleClick,
    hasStriptags,
    head,
    isBoolean,
    isFile,
    isSelect,
    urlShow,
    value,
    ...rest
  } = props
  const styledValues = { ...rest }

  let displayedValue = value
  if (hasStriptags) {
    displayedValue = stripTags(value)
  }
  if (isBoolean) {
    displayedValue = displayedValue ? 'Yes' : 'No'
    styledValues.center = true
  }
  if (isFile) {
    displayedValue = <Thumbnail src={graphCoolAdapter(value && value.url)} />
  }
  if (isSelect) {
    displayedValue = value.title
  }

  return (
    <TableRowItem {...styledValues} head={head} handleClick={handleClick}>
      {urlShow && (
        <Link color={INFO} boxed to={urlShow}>
          Voir/Modifier
        </Link>
      )}
      {askDelete && (
        <Button color={DANGER} onClick={askDelete}>
          Supprimer
        </Button>
      )}
      {displayedValue}
    </TableRowItem>
  )
}
DataTableCase.defaultProps = {
  action: '',
  askDelete: undefined,
  center: false,
  handleClick: () => null,
  hasStriptags: false,
  head: false,
  isBoolean: false,
  isFile: false,
  isSelect: false,
  urlShow: '',
  value: '',
}
DataTableCase.propTypes = {
  action: PropTypes.string,
  askDelete: PropTypes.func,
  center: PropTypes.bool,
  handleClick: PropTypes.func,
  hasStriptags: PropTypes.bool,
  head: PropTypes.bool,
  isBoolean: PropTypes.bool,
  isFile: PropTypes.bool,
  isSelect: PropTypes.bool,
  urlShow: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any),
  ]),
}

export default DataTableCase
