import React from 'react'
import styled from 'styled-components'
import BaseWrapper from '~components/Wrapper'

const Wrapper = styled(BaseWrapper).attrs((props) => {
  const { template } = props
  return {
    style: {
      gridTemplateColumns: template || '1fr 5fr',
    },
  }
})`
  display: grid;
  width: 100%;
`

/**
 * WrapperContent
 * @component
 *
 */
function WrapperContent(props) {
  return <Wrapper {...props} />
}

export default WrapperContent
