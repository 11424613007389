import React from 'react'
import WrapperHome from '~Home/components/WrapperHome'
import HomeTitle from '~Home/components/HomeTitle'

/**
 * HomeContainer
 * @component
 *
 */
function HomeContainer(props) {
  // eslint-disable-next-line
  return (
    <WrapperHome {...props}>
      <HomeTitle>Overview</HomeTitle>
    </WrapperHome>
  )
}

export default HomeContainer
