import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { STRING, BOOLEAN, NUMBER, SELECT } = constants.FIELD_TYPES
const FIELDS = `
    id
    name
    dayDate
    nbAdults
    nbChildren
    isVisited
    comments
    serviceGM {id name}
`

/**
 *
 * DefaultServiceGMSummary
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultServiceGMSummary extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.name = this.name || ''
    this.dayDate = this.dayDate || ''
    this.nbAdult = this.nbAdult || -1
    this.nbChildren = this.nbChildren || -1
    this.isVisited = this.isVisited || false
    this.comments = this.comments || false
    const serviceGM = obj.serviceGM || {}
    this.serviceGM = {
      key: serviceGM.id || serviceGM.key || '',
      title: serviceGM.name || serviceGM.title || '',
    }
  }

  static getLabel() {
    return 'Service: Groupe de Maison (Bilans)'
  }

  static hasSingleItem() {
    return false
  }

  static fields(options = {}) {
    const { skip = [], selectList = [] } = options
    const gmList = selectList[0] || [{}]
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
      { fieldName: 'dayDate', fieldType: STRING },
      { fieldName: 'nbAdult', fieldType: NUMBER },
      { fieldName: 'nbChildren', fieldType: NUMBER },
      { fieldName: 'isVisited', fieldType: BOOLEAN },
      { fieldName: 'comments', fieldType: STRING },
      {
        fieldName: 'serviceGM',
        fieldType: SELECT,
        options: gmList.map(({ name, id }) => ({
          title: name,
          key: id,
        })),
      },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getServiceGMSummary',
        selectNamesList: ['allServiceGM'],
        request: gql` query getServiceGMSummary($id: ID!) {
          getServiceGMSummary(id: $id) {
            ${FIELDS}
          }
          allServiceGM {
            id
            name
          }
        }
      `,
      },
      all: {
        name: 'allServiceGMSummaries',
        request: gql` query allServiceGMSummaries {
          allServiceGMSummaries {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postServiceGMSummary($input: ServiceGMSummaryInput!) {
            postServiceGMSummary(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.name !== '', this.serviceGM.key].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        id: this.id,
        name: this.name,
        dayDate: this.dayDate,
        nbAdult: this.nbAdult,
        nbChildren: this.nbChildren,
        isVisited: this.isVisited,
        comments: this.comments,
        serviceGM: this.serviceGM.key,
      },
    }
  }

  purge() {
    return this
  }
}

export default DefaultServiceGMSummary
