import React from 'react'
import Alert from '~components/Alert'
import CONSTANTS from '~utils/constants'

const { ALERTS } = CONSTANTS

/**
 * useAlert
 * @hook
 *
 */
function useAlert() {
  const [alert, setAlert] = React.useState(ALERTS.DEFAULT)
  const closeAlert = () => setAlert(ALERTS.DEFAULT)
  return {
    alerts: ALERTS,
    setAlert: (selectedAlert, addedAlert = '') =>
      setAlert({
        ...selectedAlert,
        message: `${selectedAlert.message}${addedAlert && `: ${addedAlert}`}`,
      }),
    clearAlert: closeAlert,
    AlertBlock: (alertProps) => (
      <Alert {...alertProps} onClose={closeAlert} {...alert} />
    ),
  }
}

export default useAlert
