import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import constants from '~/utils/constants'
import strUcFirst from '~utils/functions/strUcFirst'

import SidebarItem from '~Sidebar/components/SidebarItem'
import SidebarList from '~Sidebar/components/SidebarList'
import SidebarTitle from '~Sidebar/components/SidebarTitle'
import WrapperSidebar from '~Sidebar/components/WrapperSidebar'

const { PATHS, DATAS } = constants

/**
 * SidebarContainer
 * @component
 *
 */
export function SidebarContainer(props) {
  const { children } = props
  const { pathname } = useLocation()

  return (
    <WrapperSidebar>
      <SidebarTitle to={PATHS.DEFAULT}>Données :</SidebarTitle>
      <SidebarList>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.HOMECAROUSEL}`}
          isActive={pathname.includes(DATAS.HOMECAROUSEL)}>
          {strUcFirst(DATAS.HOMECAROUSEL)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.HOMEMESSAGE}`}
          isActive={pathname.includes(DATAS.HOMEMESSAGE)}>
          {strUcFirst(DATAS.HOMEMESSAGE)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.HOMENEWS}`}
          isActive={pathname.includes(DATAS.HOMENEWS)}>
          {strUcFirst(DATAS.HOMENEWS)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.HOMEVIDEOS}`}
          isActive={pathname.includes(DATAS.HOMEVIDEOS)}>
          {strUcFirst(DATAS.HOMEVIDEOS)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.CATEGORY}`}
          isActive={pathname.includes(DATAS.CATEGORY)}>
          {strUcFirst(DATAS.CATEGORY)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.TYPE}`}
          isActive={pathname.includes(DATAS.TYPE)}>
          {strUcFirst(DATAS.TYPE)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.SECTION}`}
          isActive={pathname.includes(DATAS.SECTION)}>
          {strUcFirst(DATAS.SECTION)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.FEATURE}`}
          isActive={pathname.includes(DATAS.FEATURE)}>
          {strUcFirst(DATAS.FEATURE)}
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.USER}`}
          isActive={pathname.includes(DATAS.USER)}>
          {strUcFirst(DATAS.USER)}
        </SidebarItem>
        <hr />
        <li style={{ padding: '0.3em' }}>
          <b>Groupe de maison: </b>
        </li>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.SERVICE_GM_DIRECTION}`}
          isActive={pathname.includes(DATAS.SERVICE_GM_DIRECTION)}>
          Direction
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.SERVICE_GM_SECTOR}`}
          isActive={pathname.includes(DATAS.SERVICE_GM_SECTOR)}>
          Secteurs
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.SERVICE_GM_AREA}`}
          isActive={pathname.includes(DATAS.SERVICE_GM_AREA)}>
          Zones
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.SERVICE_GM}`}
          isActive={pathname.includes(DATAS.SERVICE_GM)}>
          Groupes de maisons
        </SidebarItem>
        <SidebarItem
          to={`${PATHS.DATA_LIST}/${DATAS.SERVICE_GM_SUMMARY}`}
          isActive={pathname.includes(DATAS.SERVICE_GM_SUMMARY)}>
          Bilans
        </SidebarItem>
      </SidebarList>
      {children}
    </WrapperSidebar>
  )
}
SidebarContainer.defaultProps = {
  children: undefined,
}

SidebarContainer.propTypes = {
  children: PropTypes.node,
}

export default SidebarContainer
