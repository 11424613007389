import React from 'react'
// import PropTypes from 'prop-types'

import Title from '~components/Title'

/**
 * ErrorTitle
 * @component
 *
 */
function ErrorTitle(props) {
  return <Title type="h2" {...props} />
}
ErrorTitle.defaultProps = {}
ErrorTitle.propTypes = {}

export default ErrorTitle
