import React from 'react'
import PropTypes from 'prop-types'

import Button from '~components/Button'

/**
 * UserButton
 * @component
 *
 */
function UserButton(props) {
  const { success, danger } = props
  let color = 'info'
  if (success) color = 'success'
  if (danger) color = 'danger'
  return <Button color={color} {...props} />
}
UserButton.defaultProps = {
  success: false,
  danger: false,
}
UserButton.propTypes = {
  success: PropTypes.bool,
  danger: PropTypes.bool,
}

export default UserButton
