import React from 'react'
import { Route, Switch } from 'react-router-dom'

import constants from '~utils/constants'

import DetailsContainer from '~Data/containers/DetailsContainer'
import ListContainer from '~Data/containers/ListContainer'

const { DATA_LIST, DATA_SHOW } = constants.PATHS

/**
 * description
 * modules that start by uppercase ( example ~Auth) are located at
 * src/modules
 */
function Data() {
  return (
    <Switch>
      <Route
        exact
        path={`${DATA_SHOW}/:dataType/:id`}
        component={DetailsContainer}
      />
      <Route exact path={`${DATA_LIST}/:dataType`} component={ListContainer} />
    </Switch>
  )
}
Data.propTypes = {}

export default Data
