import React from 'react'
import PropTypes from 'prop-types'

import Form from '~components/Form'
import Button from '~components/Button'
import Fieldset from '~components/Fieldset'

import constants from '~utils/constants'

const { SUCCESS } = constants.STATUS

/**
 * AuthForm
 * @component
 *
 */
function AuthForm(props) {
  const { children, action, isValid, ...rest } = props
  return (
    <Form {...rest}>
      {children}
      <Fieldset center>
        <Button color={SUCCESS} onClick={action} disabled={!isValid}>
          Connect
        </Button>
      </Fieldset>
    </Form>
  )
}
AuthForm.defaultProps = {
  action: () => null,
  children: undefined,
  isValid: false,
}
AuthForm.propTypes = {
  action: PropTypes.func,
  children: PropTypes.node,
  isValid: PropTypes.bool,
}

export default AuthForm
