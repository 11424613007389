import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { BOOLEAN, STRING, TEXTAREA } = constants.FIELD_TYPES
const FIELDS = `
    id
    message
    visible
`

/**
 *
 * DefaultHomeMessage
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultHomeMessage extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.message = this.message || ''
  }

  static hasSingleItem() {
    return true
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'visible', fieldType: BOOLEAN },
      { fieldName: 'message', fieldType: TEXTAREA },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getHomeMessage',
        request: gql` query getHomeMessage($id: ID!) {
          getHomeMessage(id: $id) {
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allHomeMessages',
        request: gql` query allHomeMessages {
          allHomeMessages {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postHomeMessage($input: HomeMessageInput!) {
            postHomeMessage(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.message !== ''].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        message: this.message,
        id: this.id,
        visible: this.visible,
      },
    }
  }

  purge() {
    return this
  }
}

export default DefaultHomeMessage
