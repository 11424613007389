import React from 'react'
import PropTypes from 'prop-types'
import Wrapper from '~components/Wrapper'
import Button from '~components/Button'

import constants from '~utils/constants'

const { DANGER, SUCCESS, WARNING, INFO } = constants.STATUS

/**
 * DataModalAction
 * @component
 *
 */
function DataModalAction(props) {
  const { warning, danger, onValidate, subject, closeModal, ...rest } = props
  const handleValidate = () => {
    onValidate()
    closeModal()
  }
  const validateColor = danger
    ? DANGER
    : (() => (warning ? WARNING : SUCCESS))()
  return (
    <Wrapper {...rest}>
      <div className="header">Delete ? </div>
      <div className="body">{subject} </div>
      <div className="footer">
        <Button color={INFO} onClick={closeModal}>
          Cancel
        </Button>
        <Button color={validateColor} onClick={handleValidate}>
          Validate
        </Button>
      </div>
    </Wrapper>
  )
}
DataModalAction.defaultProps = {
  onValidate: () => null,
  closeModal: () => null,
  subject: '?',
  warning: false,
  danger: false,
}
DataModalAction.propTypes = {
  onValidate: PropTypes.func,
  closeModal: PropTypes.func,
  subject: PropTypes.string,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
}

export default DataModalAction
