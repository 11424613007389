// src/utils/functions/graphCoolAdapter

/**
 * graphCoolAdapter
 * @desc :: adapter graphcool image
 *
 */
function graphCoolAdapter(src) {
  const img = src || ''
  const isGraphCool = img.includes('graph.cool')
  if (!isGraphCool) return img
  let tmpStr = img
  tmpStr = tmpStr.replace('files', 'images')
  const arrStr = tmpStr.split('/')
  arrStr.splice(3, 0, 'v1')
  arrStr.push('1250x')
  tmpStr = arrStr.join('/')
  return tmpStr
}

export default graphCoolAdapter
