/** themes */
import DefaultTheme from '~utils/constructors/DefaultTheme'

/*
 *
 * @desc :: here is an exemple of a theme where a only want to change
 * the template * colors
 * but keep the same font sizes, status colors etc ...
 *
 * EXEMPLE: new DefaultTheme({
 *   templateColors: ['blue', 'green', 'yellow', 'black'],
 * })
 *
 * example
 * @return {
 *   COLORS : {
 *      PRIMARY: '#e7eaf6',
 *      SECONDARY: '#a2a8d3',
 *      TERTIARY: '#38598b',
 *      QUATERNARY: '#113f67',
 *      INFO: '#a1eafb',
 *      SUCCESS: '#cfee91',
 *      WARNING: '#fcc88a',
 *      DANGER: '#f59292',
 *      BACKGROUND: '#fffdfbr',
 *      FOREGROUND: '#000000b3',
 *   },
 *   FONT_SIZES : {...},
 * }
 *
 * @see src/utils/constructor/DefaultTheme.js
 *
 */
export default {
  DEFAULT: new DefaultTheme({
    templateColors: ['#57a99a', '#76dbd1', '#ecf4f3', '#d1eecc'],
    writtingColors: {
      fore: '#000000b3',
      back: '#fffdfb',
    },
    statusColors: {
      info: '#a1eafb',
      success: '#cfee91',
      warning: '#fcc88a',
      danger: '#f59292',
    },
    fontSizes: ['8px', '12px', '16px', '20px', '24px', '28px'],
  }),
}
