import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProfileContainer from '~User/containers/ProfileContainer'

import constants from '~utils/constants'

const { USER_PROFILE } = constants.PATHS

/**
 * User
 * @component
 *
 */
function User() {
  return (
    <Switch>
      <Route exact path={USER_PROFILE} component={ProfileContainer} />
    </Switch>
  )
}

export default User
