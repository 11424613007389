import React from 'react'
import PropTypes from 'prop-types'

import Select from '~components/Select'
import Label from '~components/Label'

/**
 * DataFormSelect
 * @component
 *
 */
function DataFormSelect(props) {
  const { handleChange, label, options, value, ...rest } = props
  const updateValue = (nextValue) => {
    handleChange(options.find(({ key }) => key === nextValue))
  }
  return (
    <Label>
      {label && `${label}:`}
      <Select
        {...rest}
        value={value}
        defaultValue={options[0]}
        onChange={updateValue}
        options={options}
      />
    </Label>
  )
}
DataFormSelect.defaultProps = {
  handleChange: () => null,
  label: '',
  options: [],
  value: '',
}
DataFormSelect.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
}

export default DataFormSelect
