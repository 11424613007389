import { gql } from '@apollo/client'
import { checkEmail } from 'utils/functions'

/**
 *
 * DefaultAuth
 * @constructor
 * @desc :: The constructor for the auth, to send to the server
 *
 */
class DefaultAuth {
  constructor(defaultValues = {}) {
    this.email = defaultValues.email || ''
    this.password = defaultValues.password || ''
  }

  static getQueries() {
    return {
      post: {
        name: '',
        request: gql`
          mutation postAuth($input: UserAuthInput!) {
            postAuth(input: $input) {
              token
              email
              accessToken
              lastname
              name
              extra
            }
          }
        `,
      },
    }
  }

  checkEmail() {
    return checkEmail(this.email)
  }

  checkPassword() {
    return !!this.password
  }

  isValid() {
    const isValid = [this.checkEmail(), this.checkPassword()].every(
      (check) => check === true,
    )
    return isValid
  }

  parse() {
    return {
      input: {
        email: this.email,
        password: this.password,
      },
    }
  }
}

export default DefaultAuth
