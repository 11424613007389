import React from 'react'

import { useUser } from '~contexts/UserContext'
import useLocation from '~hooks/useLocation'
import constants from '~/utils/constants'

import WrapperHeader from '~Layout/components/WrapperHeader'
import LayoutLink from '~Layout/components/LayoutLink'

const { DEFAULT, AUTH, AUTH_PROFILE, AUTH_LOGIN } = constants.PATHS

/**
 * HeaderContainer
 * @component
 *
 */
function HeaderContainer() {
  const { location } = useLocation()
  const { isAuthenticated, logout } = useUser()
  const disconnect = (e) => {
    e.preventDefault()
    logout()
  }
  return (
    <WrapperHeader>
      <div>
        <LayoutLink to={DEFAULT} isActive={location === DEFAULT}>
          Home
        </LayoutLink>
        {isAuthenticated ? (
          <LayoutLink to="" onClick={disconnect} isActive={false}>
            Logout
          </LayoutLink>
        ) : (
          <LayoutLink to={AUTH_LOGIN} isActive={location.includes(AUTH)}>
            Login
          </LayoutLink>
        )}
        {isAuthenticated && (
          <LayoutLink to={AUTH_PROFILE} isActive={location === AUTH_PROFILE}>
            Profile
          </LayoutLink>
        )}
      </div>
    </WrapperHeader>
  )
}
HeaderContainer.propTypes = {}

export default HeaderContainer
