import alerts from './alerts'
import dates from './dates'
import datas from './datas'
import eventsList from './eventsList'
import features from './features'
import fieldTypes from './fieldTypes'
import textColors from './textColors'
import paths from './paths'
import status from './status'
import themes from './themes'
import words from './words'

/**
 * constants
 * @desc ::  constants provided accross all files
 *
 */
export default {
  ALERTS: { ...alerts },
  AUTH_TOKEN: 'auth_token',
  DATAS: { ...datas },
  DATES: { ...dates },
  ERRORS: {
    CLIENT: '400',
    NOT_FOUND: '404',
    SERVER: '500',
  },
  EVENTS_LIST: [...eventsList],
  FEATURES: { ...features },
  FIELD_TYPES: { ...fieldTypes },
  FONT_SIZES: {
    VERY_SMALL: '8px',
    SMALL: '12px',
    MEDIUM: '16px',
    SEMI_BIG: '20px',
    BIG: '24px',
    VERY_BIG: '28px',
  },
  PATHS: {
    ...paths,
  },
  STATUS: {
    ...status,
  },
  STYLES: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    QUATERNARY: 'quaternary',
  },
  STORAGE_ITEMS: {
    THEME: 'themeContextStorage',
  },
  THEMES: {
    ...themes,
  },
  TEXT_COLORS: { ...textColors },
  WORDS: { ...words },
}
