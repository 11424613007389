import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { SELECT, STRING } = constants.FIELD_TYPES
const FIELDS = `
    id
    name
    serviceGMSector { id name }
    serviceGMs {name}
`

/**
 *
 * DefaultServiceGMArea
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultServiceGMArea extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    const serviceGMSector = obj.serviceGMSector || {}
    this.name = this.name || ''
    this.serviceGMSector = {
      key: serviceGMSector.id || serviceGMSector.key || '',
      title: serviceGMSector.name || serviceGMSector.title || '',
    }
  }

  static getLabel() {
    return 'Service: Groupe de Maison (Zones)'
  }

  static hasSingleItem() {
    return false
  }

  static fields(options = {}) {
    const { skip = [], selectList = [] } = options
    const serviceGMSectorList = selectList[0] || [{}]
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
      {
        fieldName: 'serviceGMSector',
        fieldType: SELECT,
        options: serviceGMSectorList.map(({ name, id }) => ({
          title: name,
          key: id,
        })),
      },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getServiceGMArea',
        selectNamesList: ['allServiceGMSector'],
        request: gql` query getServiceGMArea($id: ID!) {
          getServiceGMArea(id: $id) {
            ${FIELDS}
          }
          allServiceGMSector {
            id
            name
          }
        }
      `,
      },
      all: {
        name: 'allServiceGMArea',
        request: gql` query allServiceGMArea {
          allServiceGMArea {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postServiceGMArea($input: ServiceGMAreaInput!) {
            postServiceGMArea(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.name !== '', this.serviceGMSector.key].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        id: this.id,
        name: this.name,
        serviceGMSector: this.serviceGMSector.key,
      },
    }
  }

  purge() {
    return this
  }
}

export default DefaultServiceGMArea
