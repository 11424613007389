import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import constants from '~utils/constants'
import strUcFirst from '~utils/functions/strUcFirst'
import useAlert from '~hooks/useAlert'
import { useModal } from '~contexts/ModalContext'

import DataModalAction from '~Data/components/DataModalAction'
import DataRequestManager from '~Data/components/DataRequestManager'
import DataTable from '~Data/components/DataTable'
import DataTableCase from '~Data/components/DataTableCase'
import DataTableRow from '~Data/components/DataTableRow'
import WrapperData from '~Data/components/WrapperData'

import useDataRequest from '~Data/hooks/useDataRequest'
import ItemFactory from '~Data/constructors/factory/ItemFactory'
import useSort from '~hooks/useSort'

const { FIELD_TYPES, PATHS } = constants
const {
  BOOLEAN,
  IMAGE,
  NUMBER,
  SELECT,
  TEXTAREA,
  TEXTAREA_WYSIWYG,
} = FIELD_TYPES

/**
 *
 * ListContainer
 * @container
 *
 *
 */
function ListContainer(props) {
  const { match } = props
  const history = useHistory()
  const { dataType } = match.params
  const DefaultDataType = ItemFactory(dataType)
  const { sort, handleSort } = useSort('order')

  const queries = DefaultDataType.getQueries()

  const { loading, error, refetch, removeData, data } = useDataRequest({
    get: queries.all.request,
    remove: queries.remove.request,
  })

  const { AlertBlock, setAlert, alerts } = useAlert()
  const [items, setItems] = React.useState([])
  const { activeModal, closeModal } = useModal()
  const action = (id) => `${PATHS.DATA_SHOW}/${dataType}/${id}`
  const sendDeleteRequest = (listItem) => {
    setAlert(alerts.REMOVE_DATA)
    removeData({ variables: { id: listItem.id } }).then(() => {
      setAlert(
        alerts.REMOVE_DATA_DONE,
        listItem.name || listItem.description || '',
      )
      refetch()
    })
  }
  const askDelete = (listItem) => {
    activeModal(
      <DataModalAction
        closeModal={closeModal}
        danger
        onValidate={() => sendDeleteRequest(listItem)}
        subject={`Delete "${listItem.name || 'this element'}" ?`}
      />,
    )
  }

  React.useEffect(() => {
    const nextItems = (data && data[queries.all.name]) || []
    if (DefaultDataType.hasSingleItem() && nextItems.length === 1) {
      const urlDetails = action(nextItems[0].id)
      history.push(urlDetails)
    } else {
      setItems(nextItems.map((oneItem) => new DefaultDataType(oneItem)))
    }
    return () => {
      items.forEach((item) => item.purge())
    }
  }, [data])

  React.useEffect(() => {
    refetch()
  }, [])

  const skip = ['id', 'data', 'intro', 'content', 'display']
  return (
    <DataRequestManager isRequestLoading={loading} errorRequest={error}>
      <WrapperData
        title={DefaultDataType.getLabel() || strUcFirst(dataType)}
        add={action('add')}>
        <AlertBlock />
        <DataTable>
          <DataTableRow dataType={dataType} head>
            {DefaultDataType.fields({ skip }).map(({ fieldName }) => (
              <DataTableCase
                key={fieldName}
                head
                value={strUcFirst(fieldName)}
                handleClick={() => {
                  handleSort(fieldName)
                  refetch()
                }}
              />
            ))}
            <DataTableCase head value="Actions" />
          </DataTableRow>
          {[...items].sort(sort).map((listItem) => (
            <DataTableRow dataType={dataType} key={listItem.id}>
              {DefaultDataType.fields({ item: listItem, skip }).map(
                ({ fieldName, fieldType }) => (
                  <DataTableCase
                    key={fieldName}
                    center={[NUMBER, IMAGE].includes(fieldType)}
                    hasStriptags={[TEXTAREA, TEXTAREA_WYSIWYG].includes(
                      fieldType,
                    )}
                    isBoolean={fieldType === BOOLEAN}
                    isFile={fieldType === IMAGE}
                    isSelect={fieldType === SELECT}
                    value={listItem[fieldName]}
                  />
                ),
              )}
              <DataTableCase
                center
                urlShow={action(listItem.id)}
                askDelete={() => askDelete(listItem)}
              />
            </DataTableRow>
          ))}
        </DataTable>
      </WrapperData>
    </DataRequestManager>
  )
}
ListContainer.defaultProps = {}
ListContainer.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ListContainer
