import constants from '~utils/constants'
import { useError } from '~contexts/ErrorContext'

import DefaultCategory from '../DefaultCategory'
import DefaultFeature from '../DefaultFeature'
import DefaultHomeMessage from '../DefaultHomeMessage'
import DefaultHomeNews from '../DefaultHomeNews'
import DefaultHomeVideo from '../DefaultHomeVideo'
import DefaultHomecarousel from '../DefaultHomecarousel'
import DefaultSection from '../DefaultSection'
import DefaultServiceGM from '../DefaultServiceGM'
import DefaultServiceGMArea from '../DefaultServiceGMArea'
import DefaultServiceGMDirection from '../DefaultServiceGMDirection'
import DefaultServiceGMSector from '../DefaultServiceGMSector'
import DefaultServiceGMSummary from '../DefaultServiceGMSummary'
import DefaultType from '../DefaultType'
import DefaultUser from '../DefaultUser'

const { DATAS } = constants

/**
 * ItemFactory
 * @factory
 *
 */
function ItemFactory(dataType) {
  const { setServerError } = useError()
  if (dataType === DATAS.CATEGORY) return DefaultCategory
  if (dataType === DATAS.FEATURE) return DefaultFeature
  if (dataType === DATAS.HOMECAROUSEL) return DefaultHomecarousel
  if (dataType === DATAS.HOMEMESSAGE) return DefaultHomeMessage
  if (dataType === DATAS.HOMENEWS) return DefaultHomeNews
  if (dataType === DATAS.HOMEVIDEOS) return DefaultHomeVideo
  if (dataType === DATAS.SECTION) return DefaultSection
  if (dataType === DATAS.SERVICE_GM) return DefaultServiceGM
  if (dataType === DATAS.SERVICE_GM_AREA) return DefaultServiceGMArea
  if (dataType === DATAS.SERVICE_GM_DIRECTION) return DefaultServiceGMDirection
  if (dataType === DATAS.SERVICE_GM_SECTOR) return DefaultServiceGMSector
  if (dataType === DATAS.SERVICE_GM_SUMMARY) return DefaultServiceGMSummary
  if (dataType === DATAS.TYPE) return DefaultType
  if (dataType === DATAS.USER) return DefaultUser
  setServerError(
    ` The dataType "${dataType}" is unknown by the system, 
      please implement a corresponding constructor. 
      (see ItemFactory in DataModule)`,
  )
  return DefaultCategory // unreachable because of setServerError
}

export default ItemFactory
