import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { STRING, IMAGE } = constants.FIELD_TYPES
const FIELDS = `
    id
    name
    file {
      url
      name
    }
`

/**
 *
 * DefaultServiceGMDirection
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultServiceGMDirection extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.name = this.name || ''
    this.file = this.file || null
  }

  static getLabel() {
    return 'Service: Groupe de Maison (Directives)'
  }

  static hasSingleItem() {
    return true
  }

  static fields(options = {}) {
    const { skip = [] } = options
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
      { fieldName: 'file', fieldType: IMAGE },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getServiceGMDirection',
        request: gql` query getServiceGMDirection($id: ID!) {
          getServiceGMDirection(id: $id) {
            ${FIELDS}
          }
        }
      `,
      },
      all: {
        name: 'allServiceGMDirection',
        request: gql` query allServiceGMDirection {
          allServiceGMDirection {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postServiceGMDirection($input: ServiceGMDirectionInput!) {
            postServiceGMDirection(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.message !== ''].every((condition) => condition)
  }

  parse() {
    return {
      input: {
        name: this.name,
        id: this.id,
        file: this.file instanceof File ? this.file : null,
      },
    }
  }

  purge() {
    URL.revokeObjectURL(this.file && this.file.url)
    return this
  }
}

export default DefaultServiceGMDirection
