import styled from 'styled-components'

/**
 * BaseWrapper
 * @component
 *
 */
const BaseWrapper = styled.section``

export default BaseWrapper
