import styled from 'styled-components'

/**
 * BaseTable
 * @component
 *
 */
const BaseTable = styled.section`
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.COLORS.PRIMARY};
  box-shadow: 0 0 12px -3px ${({ theme }) => theme.COLORS.TERTIARY} inset;
  display: inline-block;
  max-height: 300px;
  max-width: 100%;
  min-height: 200px;
  overflow: hidden auto;
  padding: 0em;
  position: relative;
  @media (min-width: 768px) {
    max-height: 500px;
  }
`

export default BaseTable
