import React from 'react'
import SidebarContainer from '~Sidebar/containers/SidebarContainer'

/**
 * Sidebar
 * @component
 *
 */
function Sidebar() {
  return <SidebarContainer />
}
Sidebar.defaultProps = {}
Sidebar.propTypes = {}

export default Sidebar
