import { gql } from '@apollo/client'
import constants from '~utils/constants'
import DataInterface from './interfaces/DataInterface'
import fakeMutation from './fakeMutation'

const { STRING, SELECT } = constants.FIELD_TYPES
const FIELDS = `
    id
    name
    address
    serviceGMSummaries { name }
    serviceGMArea { name }
    members { name }
    owners{ name }
`

/**
 *
 * DefaultServiceGM
 * @constructor
 *
 * @desc :: All methods are inherited from DataInterface
 * @see :: ./interfaces/DataInterface
 *
 * @param {Object} obj
 *
 */
class DefaultServiceGM extends DataInterface {
  constructor(obj = {}) {
    super(obj)
    this.name = this.name || ''
    this.address = this.address || ''
    const serviceGMArea = obj.serviceGMArea || {}
    this.serviceGMArea = {
      key: serviceGMArea.id || serviceGMArea.key || '',
      title: serviceGMArea.name || serviceGMArea.title || '',
    }
  }

  static getLabel() {
    return 'Service: Groupe de Maison'
  }

  static hasSingleItem() {
    return false
  }

  static fields(options = {}) {
    const { skip = [], selectList = [] } = options
    const areaList = selectList[0] || [{}]
    return [
      { fieldName: 'id', fieldType: STRING },
      { fieldName: 'name', fieldType: STRING },
      { fieldName: 'address', fieldType: STRING },
      {
        fieldName: 'serviceGMArea',
        fieldType: SELECT,
        options: areaList.map(({ name, id }) => ({
          title: name,
          key: id,
        })),
      },
    ].filter(({ fieldName }) => !skip.includes(fieldName))
  }

  static getQueries() {
    return {
      one: {
        name: 'getServiceGM',
        selectNamesList: ['allServiceGMArea'],
        request: gql` query getServiceGM($id: ID!) {
          getServiceGM(id: $id) {
            ${FIELDS}
          }
          allServiceGMArea {
            id
            name
          }
        }
      `,
      },
      all: {
        name: 'allServiceGM',
        request: gql` query allServiceGM {
          allServiceGM {
            ${FIELDS}
          }
        }
      `,
      },
      post: {
        name: '',
        request: gql`
          mutation postServiceGM($input: ServiceGMInput!) {
            postServiceGM(input: $input) {
              id
            }
          }
        `,
      },
      remove: {
        name: '',
        request: fakeMutation,
      },
    }
  }

  isValid() {
    return [this.message !== '', this.serviceGMArea.key].every(
      (condition) => condition,
    )
  }

  parse() {
    return {
      input: {
        name: this.name,
        id: this.id,
        address: this.address,
        serviceGMArea: this.serviceGMArea.key,
      },
    }
  }

  purge() {
    return this
  }
}

export default DefaultServiceGM
