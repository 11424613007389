import React from 'react'
import PropTypes from 'prop-types'

import Image from '~components/Image'
import DraggableArea from '~components/DraggableArea'

import constants from '~utils/constants'
import { graphCoolAdapter } from '~utils/functions'

const { WORDS } = constants

/**
 * DataFormImage
 * @component
 *
 */
function DataFormImage(props) {
  const { src, tmpSrc, handleChange, ...rest } = props
  const [srcImg, updateImg] = React.useState(src)
  const setSrcImg = (str) => {
    const isGraphCool = str.includes('graph.cool')
    let tmpStr = str
    if (isGraphCool) {
      tmpStr = graphCoolAdapter(str)
    }
    updateImg(tmpStr)
  }
  const text = srcImg ? '' : WORDS.DRAG_FILE

  const handleDrop = async (e) => {
    const file = e[0]
    if (!file) return
    const nextURL = await URL.createObjectURL(file)
    setSrcImg(nextURL)
    handleChange(file, nextURL)
  }
  const handleClickImg = () => {
    setSrcImg('')
    handleChange(null)
  }
  React.useEffect(() => {
    if (src) setSrcImg(src)
    if (tmpSrc) setSrcImg(tmpSrc)
  }, [src, tmpSrc])
  return (
    <DraggableArea placeholder={text} onDrop={handleDrop} clickable={!srcImg}>
      {srcImg && (
        <Image src={srcImg} width="100px" onClick={handleClickImg} {...rest} />
      )}
    </DraggableArea>
  )
}
DataFormImage.defaultProps = {
  src: '',
  tmpSrc: '',
  handleChange: () => null,
}
DataFormImage.propTypes = {
  src: PropTypes.string,
  tmpSrc: PropTypes.string,
  handleChange: PropTypes.func,
}

export default DataFormImage
