import React from 'react'
import PropTypes from 'prop-types'
import Link from '~components/Link'

/**
 * SidebarTitle
 * @component
 *
 */
function SidebarTitle(props) {
  return <Link size="2em" {...props} />
}
SidebarTitle.defaultProps = {}
SidebarTitle.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default SidebarTitle
